import request from '@/utils/requestV2'

// 获取日期控件的开始日期-按月下拉
export function getStartDateList (data) {
  return request({
    url: '/ooh-dw/v1/datav/time/getStartDateList',
    method: 'get',
    params: data
  })
}
// 获取日期控件的结束日期-按月下拉
export function getEndDateList (data) {
  return request({
    url: '/ooh-dw/v1/datav/time/getEndDateList',
    method: 'get',
    params: data
  })
}
