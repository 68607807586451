import request from '@/utils/requestV2'

// 统计小微的合同金额-按小微行业分组
export function listMicroAmountAndConversionRateGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroAmountAndConversionRateGb',
    method: 'get',
    params: data
  })
}

// 统计小微资源售卖块天-按小微行业分组
export function listMicroResourceSaleDayGbIndustry (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroResourceSaleDayGbIndustry',
    method: 'get',
    params: data
  })
}

// 统计小微资源售卖块天-按站点分组
export function listMicroResourceSaleDayGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroResourceSaleDayGbStation',
    method: 'get',
    params: data
  })
}

// 统计小微指标-按网格分组
export function listMicroIndexGbCell (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroIndexGbCell',
    method: 'get',
    params: data
  })
}

// 根据小微签约金额大小排序，选取tpp10的站点
export function listMicroStationTop10 (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroStationTop10',
    method: 'get',
    params: data
  })
}

// 统计小微销售用户的登录次数、登录次数占总登录次数比率、拜访数、签约合同数、退款额
export function listMicroUserBehavior (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroUserBehavior',
    method: 'get',
    params: data
  })
}

// 统计小微签约金额和成交客户数，按照站点分组
export function listMicroSignAmountGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroSignAmountGbStation',
    method: 'get',
    params: data
  })
}

// 统计小微商家数据并分组
export function countMicroNumberGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/countMicroNumberGb',
    method: 'get',
    params: data
  })
}

// 统计拜访商家数并分组
export function countRecordNumberGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/countRecordNumberGb',
    method: 'get',
    params: data
  })
}

// 统计小微的签约客户转化率
export function listMicroBusinessConvertRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroBusinessConvertRate',
    method: 'get',
    params: data
  })
}
// 统计小微的签约额占总经营签约金额的比率
export function listMicroBusinessAmountRate (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroBusinessAmountRate',
    method: 'get',
    params: data
  })
}

// 根据RFM模型对小微客户进行价值分类
export function listMicroBusinessValueClassification (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroBusinessValueClassification',
    method: 'get',
    params: data
  })
}

// 根据RFM模型对小微客户进行价值分类
export function listMicroIndexGbMonth (data) {
  return request({
    url: '/ooh-dw/v1/datav/micro/listMicroIndexGbMonth',
    method: 'get',
    params: data
  })
}
