<style lang="less">
// .map-search {
//   position: absolute;
//   top: 45px;
//   left: 20px;
//   z-index: 999;
// }
#container {
  position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  height: 942px;
  width: 1250px;
}
#container > * {
  position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div>
    <div class="center-block-date">
      <Select
        v-model="query.type"
        style="width:100px"
        class="m-r-5"
        size="small"
        @on-change="handleChangeType"
      >
        <Option
          v-for="item in typeArray"
          :key="'type_'+item.value"
          :value="item.value"
        >{{item.name}}</Option>
      </Select>
      <Select
        v-model="query.startDate"
        style="width:100px"
        size="small"
        @on-change="handleChangeDate"
      >
        <Option
          v-for="(date,index) in startDateArray"
          :key="index"
          :value="date.key"
        >{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select
        v-model="query.endDate"
        style="width:100px"
        size="small"
        @on-change="handleChangeDate"
      >
        <Option
          v-for="(date,index) in endDateArray"
          :key="index"
          :value="date.key"
        >{{date.value}}</Option>
      </Select>
    </div>
    <div id="container">
      <div id="map"></div>
      <canvas id="deck-canvas"></canvas>
    </div>
  </div>
</template>

<script>
import { Deck } from '@deck.gl/core'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import mapboxgl from 'mapbox-gl'

import { getStorage } from '@/utils/storage'
import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { getCompany } from '@/api/os/company'
import { getCity } from '@/api/sys/provincecityregion'
import { listMicroIndexGbCell } from '@/api/dw/micro'

var INITIAL_VIEW_STATE = {
  longitude: 120.43,
  latitude: 36.12,
  zoom: 9,
  maxZoom: 20,
  pitch: 30,
  bearing: 0
}

export default {
  data () {
    return {
      query: {
        endDate: '',
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        type: 4,
        industryId: null,
        userId: null // 渠道id
      },
      startDateArray: [],
      endDateArray: [],
      typeArray: [
        { value: 4, name: '商家浓度' },
        { value: 1, name: '转化率' },
        { value: 2, name: '开发率' },
        { value: 3, name: '投放额' }
      ],
      userInfo: this.$store.getters.token.userInfo,
      companyInfo: {},
      cellData: []
    }
  },
  computed: {
    selectedIndustryId () {
      return this.$store.state.situationWeb.selectedIndustryId
    },
    selectedUserId () {
      return this.$store.state.situationWeb.selectedUserId
    },
    theme () {
      return getStorage('oohforce-workbench-theme') || 'dark'
    }
  },
  mounted () {
    const y = new Date().getFullYear()
    const m = new Date().getMonth() // 获取上个月，当前不需要获取本月
    const d = new Date(y, m, 0).getDate() // 获取指定月份最后一天
    this.query.startDate = `${y}-${m.toString().padStart(2, '0')}-01`
    this.query.endDate = `${y}-${m.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}`
    this.handleChangeDate()

    this.getCompanyInfo()
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    handleChangeDate () {
      const dateRange = {
        startDate: this.query.startDate,
        endDate: this.query.endDate
      }

      this.$store.commit('set_date_range', dateRange)
    },
    // 初始化地图
    initMap () {
      mapboxgl.accessToken = 'pk.eyJ1Ijoib29oZm9yY2UiLCJhIjoiY2tueWJ3MDI2MWV0cjJ2b2F6ajQ2aW56biJ9.hYCo3zTga7fCNZCxP9NcCA'
      const mapUrl = this.theme === 'dark' ? 'https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}' : 'https://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
      const map = new mapboxgl.Map({
        container: 'map', // container id
        style: {
          version: 8,
          sources: {
            'raster-tiles': {
              type: 'raster',
              tiles: [mapUrl],
              tileSize: 256
            }
          },
          layers: [{
            id: 'simple-tiles',
            type: 'raster',
            source: 'raster-tiles',
            minzoom: 0,
            maxzoom: INITIAL_VIEW_STATE.maxZoom
          }]
        },
        center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude], // starting position
        zoom: INITIAL_VIEW_STATE.zoom,
        minZoom: 5,
        bearing: INITIAL_VIEW_STATE.bearing,
        pitch: INITIAL_VIEW_STATE.pitch
      })

      this.deck = new Deck({
        canvas: 'deck-canvas',
        // width: '100%',
        // height: '100%',
        initialViewState: INITIAL_VIEW_STATE,
        controller: true,
        onViewStateChange: ({ viewState }) => {
          map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            bearing: viewState.bearing,
            pitch: viewState.pitch
          })
        }
      })
    },
    handleChangeType () {
      this.getCellData()
    },
    // 获取公司信息
    getCompanyInfo () {
      getCompany({ companyId: this.userInfo.companyId, publisherId: this.userInfo.publisherId }).then(res => {
        if (res && !res.errcode) {
          this.companyInfo = res
        }
      }).then(() => {
        this.getCityInfo()
      })
    },
    getCityInfo () {
      if (this.companyInfo.cityCode) {
        getCity({ cityCode: this.companyInfo.cityCode }).then(res => {
          if (res && !res.errcode) {
            INITIAL_VIEW_STATE.longitude = parseFloat(res.coordinate.split(',')[0])
            INITIAL_VIEW_STATE.latitude = parseFloat(res.coordinate.split(',')[1])
          }
          this.initMap()
        }).then(() => {
          this.getCellData()
        })
      }
    },
    /**
     * 获取网格数据
     */
    getCellData () {
      listMicroIndexGbCell(this.query).then(res => {
        if (res && !res.errcode) {
          this.cellData = res
          // 初始化处理网格图层渲染
          this.$nextTick(() => {
            const layers = new H3HexagonLayer([])
            this.deck.setProps({ layers: [layers] })
            this.updateLayer()
          })
        }
      })
    },
    // 更新图层
    updateLayer (highlightIndex = -1) {
      const layers = new H3HexagonLayer({
        id: 'H3HexagonLayer',
        data: this.cellData,

        /* props from H3HexagonLayer class */

        elevationScale: 10,
        extruded: true,
        filled: true,
        stroked: true,
        wireframe: true,
        pickable: true,
        getElevation: 5,
        getFillColor: d => {
          return [255, (1 - d.value / 50) * 255, (1 - d.value / 255) * 255]
        },
        getLineColor: [169, 169, 169],
        getHexagon: d => d.id,
        opacity: 0.6,
        // autoHighlight: true,
        highlightColor: [66, 133, 244],
        highlightedObjectIndex: highlightIndex,
        updateTriggers: {
          // 填充颜色实时更新
          getFillColor: d => [255, (1 - d.value / 50) * 255, (1 - d.value / 255) * 255]
        }
      })
      this.deck.setProps({ layers: [layers] })
    }
  },
  watch: {
    selectedIndustryId (val) {
      this.query.userId = 0
      this.query.industryId = val
      this.getCellData()
    },
    selectedUserId (val) {
      this.query.userId = val
      this.query.industryId = 0
      this.getCellData()
    }
  }
}
</script>
